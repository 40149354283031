@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Unbounded:wght@200&display=swap');
html{
	background-color: transparent !important;
}
body {
	background-image: url('./assets/img/background.png');
	// background-size: cover;
	// background-position: center center;
	font-family: 'Montserrat', sans-serif;
}

.container {
	// min-height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;
}

.content {
	margin-top: 10%;
	width: 50%;
	background-color: #FFF;
	height: auto;
	border-radius: 15px;
}

.w-300 {
	width: 300px;
}

.mt-20 {
	margin-top: 20px
}

.text-center {
	text-align: center;
}

.text {
	line-height: 20px;
	padding: 9px;
	font-size: 15px;
}

.footer-text {
	padding: 20px;
	font-size: 10px;
}

.lumen-footer-logo {
	width: 50%;
	position: absolute;
	margin-top: 12px;
	text-align: center;
}

.buttonWrap {
	position: relative;
}

#blocker {
	position: absolute;
	height: 100%;
	width: 100%;
	background: rgba(255, 255, 255, 0.7);
	cursor: not-allowed;
}

.button {
	background: #2a86d7;
	color: #fff;
	padding: 10px;
	border: none;
	width: 60%;
	height: 50px;
	border-radius: 22px;
	margin-bottom: 10px;
	font-family: 'Montserrat';
	cursor: pointer;
}

.button:disabled {
	cursor: not-allowed;
	background-color: #9ab0c3;
}

.gug_logo {
	width: 80px;
	vertical-align: middle;
	padding-bottom: 4px;
}

.powered {
	margin-top: -30px;
}

@media screen and (max-width: 768px) {
	.lumen-footer-logo {
		width: 100%;
		position: absolute;
		margin-top: 12px;
		text-align: center;
	}

	.text {
		line-height: 20px;
		padding: 9px;
		font-size: 15px;
	}

	.text-check {
		font-size: 12px
	}

	.content {
		width: 100%;
	}
}